<template>
  <land-section
    id="scheme-disclose"
    :color="backColor"
    :space="space"
  >
    <v-container
      style="max-width: 1200px;"
    >
      <v-row
        justify="center"
      >
        <v-col
          cols="12"
          md="8"
        >
          <v-tabs
            v-model="dbMain.active"
            background-color="transparent"
          >
            <v-tab
              v-for="(tab, tabIndex) in dbMain.tabs"
              :key="`tab_${tabIndex}`"
              :href="`#${tab.name}`"
              class="text-h6"
            >
              {{ tab.title }}
            </v-tab>
            <v-spacer />
            <div class="co-h-full d-flex flex-column justify-center items-center">
              <v-btn
                icon
                @click="onNoticeMore($event, dbMain.active)"
              >
                <v-icon>mdi-chevron-right</v-icon>
              </v-btn>
            </div>
          </v-tabs>
          <v-tabs-items v-model="dbMain.active">
            <v-tab-item
              v-for="(tab, tabIndex) in dbMain.tabs"
              :key="`tab_${tabIndex}`"
              :value="tab.name"
            >
              <land-plain-notices
                :items="tab.items"
                dense
                :back-color="backColor"
                :card-color="backColor"
                padding="py-4"
                @click="onNotice($event, tab)"
              />
            </v-tab-item>
          </v-tabs-items>
        </v-col>
        <v-col
          cols="12"
          md="4"
        >
          <div class="d-flex flex-column co-mt2">
            <land-gallery
              v-for="obj in dbSide"
              :key="obj.name"
              :items="obj.items"
              floated
              per-columns="1"
              ratio="2.35"
              back-color="grey lighten-5"
              @click="onGallery"
            />
          </div>
        </v-col>
      </v-row>
    </v-container>
  </land-section>
</template>

<script>
  import mixHomeDisclose from '@/pages/mixins/home/mix.home.disclose'

  export default {
    name: 'SectionSchemeDisclose',
    mixins: [mixHomeDisclose],
    props: {
      backColor: {
        type: String,
        default: 'white'
      },
      space: {
        type: [Number, String],
        default: 56
      }
    },
    created () {
      this.initParams()
      this.loadData()
    }
  }
</script>
